import React from "react"
import LayoutComponent from "../../components/Layout.js"
import RoentgenCT from "../../components/RoentgenCT.js"
import Knap from "../../components/Knap.js"

const RoentgenPage = () => (
    <LayoutComponent>
        <h1>Røntgen og CT</h1>
        <p>I samarbejde med vores leverandører kan vi levere næsten alt i røntgen- og CT tilbehør. Vi kan blandt andet hjælpe med røntgenbeskyttelse og blybeskyttelse i form af røntgenforklæder, blyforklæder, blyskjolde, blyafdækning, gonadebeskyttere eller røntgenbriller, samt levere røntgentilbehør til positionering, kvalitetskontrol (QA), dosimetre, dosimetri, skanning, skanningsudstyr, billeddiagnostik, kalibrering, markeringer og mammografi.</p>
        <p>Derudover er vi leveringsdygtige indenfor fantomer, træningsfantomer, vævssimulation og patientsimulatorer.</p>
        <h1>Vores leverandører</h1>
        <RoentgenCT />    
        <Knap />
    </LayoutComponent>
)
export default RoentgenPage